<template>
<div style="height:100%;position:relative;">
   <div v-if="!showIdle && resultItem && resultItem.err_code"
      style="z-index:8;top:0;bottom:0;left:0;right:0;position:absolute;background-color:#000000A2;">
      <v-row style="height:100%;width:100%;" justify="center" align="center">
         <v-col style="background-color:#FFFFFF33;margin:auto;max-width:88vmin;border-radius:2vmin;padding:5vmin;text-align:center;font-size:4.7vmin;font-weight:500;"
         :style="{color: 'yellow'}">
            {{resultItem.message||resultItem.err_code}}
         </v-col>
      </v-row>
   </div>

   <div style="z-index:1;top:0;bottom:0;left:0;right:0;position:absolute;">
      <div style="height:100%;background-color:#555555;"
         class="pa-0">
         <div v-if="showIdle"
            class="pa-1"
            style="width:100%;height:100%;background-color:black;">
            <v-img :src="adsUrl" contain  
               style="width:100%;height:100%;background-color:black;"/>
         </div>
         <template v-if="!showIdle">
            <div v-if="resultItem"
               style="height:100%;">
               <!-- Cam div -->
               <div style="background-color:#DEDEDE;height: calc(100% - 33vmin - 50px);">
                  <div style="height: 50%;">
                     <camera-stream-widget 
                        :streamUrl="camStreamBig1"
                        :staticImg="staticImgBig1" />
                  </div>
                  <div style="height: 50%;background-color:orange;">
                     <camera-stream-widget
                        :streamUrl="camStreamBig2"
                        :staticImg="staticImgBig2" />
                  </div>
               </div>

               <!-- record block -->
               <div v-if="resultItem"
                  style="background-color:#888888;height: calc(33vmin + 50px);">
                  <div v-if="resultItem.user">
                     <div class="active-fullname">
                        {{resultItem.user.companyCode}} - {{resultItem.user.fullname}}
                     </div>
                  </div>
                  <div style="align:left;" class="ma-1">
                     <div class="user-face ma-1">
                        <camera-stream-widget 
                           :streamUrl="camStreamSmall1"
                           :staticImg="staticSmall1" />
                     </div>
                     <div class="user-face ma-1">
                        <camera-stream-widget 
                           :streamUrl="camStreamSmall2"
                           :staticImg="staticSmall2" />
                     </div>
                     <div class="info-container ma-1">
                        <parking-access-item class="mb-3"
                           :pRecord="resultItem.recordIn"/>
                        <parking-access-item 
                           :pRecord="resultItem.recordOut"/>
                     </div>
                  </div>
               </div>
            </div>
         </template>
      </div>
   </div>
</div>
</template>

<script>
export default {
   sockets: {
      cam7010 (imageBase64) {
         this.staticImg2 = "data:image/jpeg;charset=utf-8;base64," + imageBase64
      },
      cam6070 (imageBase64) {
         this.staticImg1 = "data:image/jpeg;charset=utf-8;base64," + imageBase64
      },
      showParkingAccess (record) {
         this.resultItem = record
         this.staticImgBig1 = this.resultItem.staticImgBig1
         this.staticImgBig2 = this.resultItem.staticImgBig2
         this.staticSmall1 = this.resultItem.staticSmall1
         this.staticSmall2 = this.resultItem.staticSmall2
         // "data:image/jpeg;charset=utf-8;base64," 
         this.setIdleTimeout(record.timeout)
      },
   },
   data () {
      return {
         adsUrl: '/imgs/5k.jpg',
         idleAfter: new Date().getTime(),
         showIdle: true,
         camStreamBig1: "",  // ws://parking:9920
         camStreamBig2: "", // ws://parking:9921
         camStreamSmall1: "",  // ws://parking:9920
         camStreamSmall2: "", // ws://parking:9921
         staticImgBig1: "",
         staticImgBig2: "",
         staticSmall1: "",
         staticSmall2: "",
         currentMode: '',
         resultItem: null,
         oneSecTimer: null,
         // resultItem: {
         //    user: {
         //       _id: "123-12-345",
         //       user_code: "001",
         //       fullname: "Lương Nguyễn Nhân",
         //       register_face: "",
         //       register_plate: "",

         //    },
         //    recordIn: {
         //       plate: '65W112346',
         //       at: new Date(),
         //       direction: 'in',
         //       plate_imgs: [],
         //       face_imgs: []
         //    },
         //    recordOut: {
         //       plate: '65W112346',
         //       at: new Date(),
         //       direction: 'out',
         //       plate_imgs: [],
         //       face_imgs: []
         //    }
         // },
      }
   },
   methods: {
      onOneSecTimer () {
         this.showIdle = new Date().getTime() > this.idleAfter.getTime()
      },
      setIdleTimeout (timeout) {
         this.idleAfter = new Date(Date.now() + (timeout||5000))
      }
   },
   created () {
      let self = this
      this.oneSecTimer = setInterval(function () {
         self.onOneSecTimer()
      }, 1000)
    },
    destroyed() {
      clearInterval(this.oneSecTimer)
    }
}
</script>

<style lang="scss">
   .active-fullname {
      background-color: #827717;
      color:white;
      font-size: 3.5vmin;
      line-height: 2;
      font-weight: 600;
      text-align: center;
   }
   .user-face {
      background-color: black;
      display: inline-block;
      width: 29vmin;
      height: 29vmin;
      float:left;
   }
   .info-container {
      background-color: #66666600;
      display: inline-block;
      width: calc(100vmin - 29vmin - 29vmin - 6.5vmin);
      height: 29vmin;
      float: right;
   }
</style>